var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    {
      ref: "scrollContainer",
      staticClass: "scroll-container",
      attrs: { vertical: false }
    },
    [
      _c(
        "el-menu",
        {
          attrs: {
            "default-active": _vm.activeMenu,
            "background-color": _vm.backgroundColor,
            "text-color": _vm.textColor,
            "unique-opened": false,
            "active-text-color": _vm.textColor,
            "collapse-transition": false,
            mode: "horizontal"
          }
        },
        _vm._l(_vm.appNavbar, function(route) {
          return _c("menu-item", {
            key: route.path,
            attrs: {
              item: route,
              collapse: _vm.isCollapse,
              "base-path": route.path
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }