"use strict";

var _interopRequireWildcard = require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("E:\\start-cms\\back-front\\node_modules\\core-js\\modules\\es6.array.iterator.js");

require("E:\\start-cms\\back-front\\node_modules\\core-js\\modules\\es6.promise.js");

require("E:\\start-cms\\back-front\\node_modules\\core-js\\modules\\es6.object.assign.js");

require("E:\\start-cms\\back-front\\node_modules\\core-js\\modules\\es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

var _microApp = _interopRequireDefault(require("@micro-zoe/micro-app"));

require("./styles/element-variables.scss");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _auth = _interopRequireDefault(require("@/directive/auth"));

require("./icons");

require("./auth");

require("./utils/error-log");

var filters = _interopRequireWildcard(require("./filters"));

// a modern alternative to CSS resets
// global css
// 水波纹指令
// 权限指令
// icon
// auth control
// error log
// global filters
_vue.default.use(_auth.default);

_vue.default.use(_waves.default);

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small' // set element-ui default size

}); // register global utility filters


Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;

_microApp.default.start();

new _vue.default({
  el: '#start',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});