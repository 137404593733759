var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icons-container" },
    [
      _vm._m(0),
      _c(
        "el-tabs",
        { attrs: { type: "border-card" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "菜单图标" } },
            _vm._l(_vm.svgIcons, function(item) {
              return _c(
                "div",
                {
                  key: item,
                  on: {
                    click: function($event) {
                      _vm.handleClipboard(_vm.generateIconCode(item), $event)
                    }
                  }
                },
                [
                  _c("el-tooltip", { attrs: { placement: "top" } }, [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(" " + _vm._s(_vm.generateIconCode(item)) + " ")
                    ]),
                    _c(
                      "div",
                      { staticClass: "icon-item" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": item,
                            "class-name": "disabled"
                          }
                        }),
                        _c("span", [_vm._v(_vm._s(item))])
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            }),
            0
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "操作图标" } },
            _vm._l(_vm.elementIcons, function(item) {
              return _c(
                "div",
                {
                  key: item,
                  on: {
                    click: function($event) {
                      _vm.handleClipboard(
                        _vm.generateElementIconCode(item),
                        $event
                      )
                    }
                  }
                },
                [
                  _c("el-tooltip", { attrs: { placement: "top" } }, [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        " " + _vm._s(_vm.generateElementIconCode(item)) + " "
                      )
                    ]),
                    _c("div", { staticClass: "icon-item" }, [
                      _c("i", { class: "el-icon-" + item }),
                      _c("span", [_vm._v(_vm._s(item))])
                    ])
                  ])
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "margin-top text-center" }, [
      _c("p", { staticClass: "margin-0 text-xl" }, [_vm._v("内置图标")]),
      _c("p", { staticClass: "text-sm text-gray" }, [
        _vm._v("该页面仅调试模式下显示")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }