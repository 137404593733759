"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.match");

require("core-js/modules/web.dom.iterable");

var req = require.context('@/icons/svg', false, /\.svg$/);

var requireAll = function requireAll(requireContext) {
  return requireContext.keys();
};

var re = /\.\/(.*)\.svg/;
var svgIcons = requireAll(req).map(function (i) {
  return i.match(re)[1];
});
var _default = svgIcons;
exports.default = _default;