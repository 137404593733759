var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout", class: _vm.classObj }, [
    _vm.device === "mobile" && _vm.sidebar.opened
      ? _c("div", {
          staticClass: "drawer-bg",
          on: { click: _vm.handleClickOutside }
        })
      : _vm._e(),
    _c(
      "div",
      { staticClass: "layout-container" },
      [
        _vm.layout === "left"
          ? _c("sidebar", { staticClass: "sidebar-container" })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "header-container" },
          [
            _vm.layout === "top"
              ? _c("navbar", { staticClass: "navbar-container" })
              : _vm._e(),
            _vm.needTagsView
              ? _c("tags-view", { attrs: { collapse: !_vm.sidebar.opened } })
              : _vm._e()
          ],
          1
        ),
        _c("app-main", { staticClass: "main-container" }),
        _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }