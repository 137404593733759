"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user");

var _auth = require("@/utils/auth");

var _router = require("@/router");

var state = {
  info: {},
  token: (0, _auth.getToken)(),
  authorize: false
};
var mutations = {
  SET_INFO: function SET_INFO(state, data) {
    state.info = data;
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_AUTHORIZE: function SET_AUTHORIZE(state, authorize) {
    state.authorize = authorize;
  }
};
var actions = {
  // 登录
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var account = userInfo.account,
        password = userInfo.password,
        code = userInfo.code,
        uniqid = userInfo.uniqid;
    return new Promise(function (resolve, reject) {
      (0, _user.login)({
        account: account.trim(),
        password: password.trim(),
        code: code.trim(),
        uniqid: uniqid
      }).then(function (response) {
        var data = response.data;
        commit("SET_TOKEN", data.token);
        (0, _auth.setToken)(data.token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 登出
  logout: function logout(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state,
        dispatch = _ref2.dispatch;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)().then(function (response) {
        commit("SET_TOKEN", "");
        commit("SET_AUTHORIZE", "");
        (0, _auth.removeToken)();
        (0, _router.resetRouter)(); // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485

        dispatch("tagsView/delAllViews", null, {
          root: true
        });
        resolve();
      });
    });
  },
  // 注册
  register: function register(_ref3, userInfo) {
    var commit = _ref3.commit;
    var mobile = userInfo.mobile,
        code = userInfo.code;
    return new Promise(function (resolve, reject) {
      (0, _user.register)({
        mobile: mobile.trim(),
        code: code.trim()
      }).then(function (response) {
        var data = response.data;
        commit("SET_TOKEN", data.token);
        (0, _auth.setToken)(data.token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 获取登录态
  getState: function getState(_ref4) {
    var commit = _ref4.commit,
        state = _ref4.state,
        rootState = _ref4.rootState;
    return new Promise(function (resolve, reject) {
      (0, _user.getState)().then(function (response) {
        var _response$data = response.data,
            info = _response$data.info,
            config = _response$data.config,
            authorize = _response$data.authorize;

        if (authorize) {
          commit("SET_AUTHORIZE", authorize);
        } else {
          reject(new Error("拉取用户权限失败"));
        }

        commit("SET_INFO", info);

        if (config) {
          commit("core/UPDATE_CONFIG", config, {
            root: true
          });
        }

        resolve(response.data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 设置Token
  setToken: function setToken(_ref5, data) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      commit("SET_TOKEN", data);
      (0, _auth.setToken)(data);
      resolve();
    });
  },
  // 删除token
  resetToken: function resetToken(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve) {
      commit("SET_TOKEN", "");
      commit("SET_AUTHORIZE", "");
      (0, _auth.removeToken)();
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;