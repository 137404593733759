"use strict";

var _interopRequireDefault = require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Msg',
  data: function data() {
    return {
      visible: false
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['msg'])),
  methods: {
    toggle: function toggle() {
      this.visible = !this.visible;
    },
    hidden: function hidden() {
      this.visible = false;
    },
    show: function show() {
      this.visible = true;
    }
  }
};
exports.default = _default;