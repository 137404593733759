var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      ref: "drawer",
      attrs: {
        "with-header": false,
        size: "500px",
        "before-close": _vm.hidden,
        visible: _vm.visible,
        direction: "rtl",
        "custom-class": "drawer-page",
        "append-to-body": true,
        modal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "drawer-header flex justify-between align-center" },
        [
          _c("div", { staticClass: "text-df" }, [
            _c("i", { staticClass: "el-icon-bell margin-right-xs" }),
            _vm._v("最新消息")
          ]),
          _c("i", {
            staticClass: "el-icon-close pointer",
            on: {
              click: function($event) {
                _vm.visible = false
              }
            }
          })
        ]
      ),
      _c(
        "div",
        { staticClass: "drawer-content" },
        [
          _c(
            "el-collapse",
            { attrs: { value: "", accordion: "" } },
            _vm._l(_vm.msg, function(item, index) {
              return _c("el-collapse-item", { key: index }, [
                _c(
                  "div",
                  {
                    staticClass: "flex justify-between flex-sub",
                    attrs: { slot: "title" },
                    slot: "title"
                  },
                  [
                    _c("span", { staticClass: "text-cut flex-auto" }, [
                      _vm._v(_vm._s(item.title))
                    ]),
                    _c(
                      "span",
                      { staticClass: "text-grey flex-shrink padding-lr-sm" },
                      [_vm._v(_vm._s(item.create_time) + "2022-03-05")]
                    )
                  ]
                ),
                _c("div", {
                  staticClass: "padding",
                  domProps: { innerHTML: _vm._s(item.content) }
                })
              ])
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }