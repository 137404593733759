"use strict";

var _interopRequireDefault = require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

var _typeof2 = _interopRequireDefault(require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/typeof"));

require("core-js/modules/web.dom.iterable");

var _utils = require("@/utils");

/**
 * xiegaolei
 */
// 格式化成多维数组
function listToTree(list) {
  var root = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var pk = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  var pid = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'pid';
  var child = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'children';
  var other = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
  var tree = [];

  if (list) {
    var newList = JSON.parse(JSON.stringify(list));
    newList.forEach(function (item) {
      if (item[pid] === root) {
        if (other !== null) {
          item = (0, _utils.objectMerge)(item, other);
        }

        var children = listToTree(list, item[pk], pk, pid, child, other);

        if (children.length) {
          item[child] = children;
        }

        tree.push(item);
      }
    });
  }

  return tree;
} // 格式化成一维数组


function treeToList(tree) {
  var child = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'children';
  var list = [];

  if (tree) {
    var newTree = JSON.parse(JSON.stringify(tree));
    newTree.forEach(function (item) {
      list = list.concat(item);

      if (item[child]) {
        list = list.concat(treeToList(item[child]));
      }
    });
  }

  list.forEach(function (item) {
    item.children = [];
  });
  return list;
} // 获取所有父id


function getParentsId(list, id) {
  var pk = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  var pid = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'pid';
  var ids = [];

  if (list) {
    list.forEach(function (item) {
      if (item[pk] === id) {
        if (item[pid] != 0) ids.unshift(item[pid]);
        var parent = getParentsId(list, item[pid], pk, pid);

        if (parent.length) {
          ids = parent.concat(ids);
        }
      }
    });
  }

  return ids;
} // 获取所有子id


function getChildrenId(list, id) {
  var pk = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  var pid = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'pid';
  var tree = [];

  if (list) {
    list.forEach(function (item) {
      if (item[pk] === id) {
        tree.unshift(item[pid]);
        var parent = getChildrenId(list, item[pid], pk, pid);

        if (parent.length) {
          tree = tree.concat(parent);
        }
      }
    });
  }

  return tree;
} // 更新列表值


function updateList(list, key, val, source) {
  if (list) {
    list.map(function (item) {
      if (item[key] === val) {
        return (0, _utils.objectMerge)(item, source);
      } else {
        updateList(item.children, key, val, source);
      }
    });
  }

  return list;
} // 递归添加值


function createTree(tree, key, val) {
  var newTree = [];

  if (tree) {
    newTree = tree.map(function (item) {
      item[key] = val;

      if (item.children) {
        createTree(item.children, key, val);
      }

      return item;
    });
  }

  return newTree;
} // 递归修改值


function updateTree(tree, key, val, source) {
  var newTree = [];

  if (tree) {
    newTree = tree.map(function (item) {
      if (item[key] === val) {
        (0, _utils.objectMerge)(item, source);

        if (item.children) {
          delete source[key];
          item.children = updateTree(item.children, 'pid', item.id, source);
        }
      } else if (item.children) {
        item.children = updateTree(item.children, key, val, source);
      }

      return item;
    });
  }

  return newTree;
} // 通过子节点的数据，计算出父节点的数据


function childrenToParent(tree, key) {
  if (tree) {
    var sum = 0;
    var avg = 0;
    tree.forEach(function (item) {
      if (item.children) {
        avg += childrenToParent(item.children, key);
        item[key] = avg;
      }

      sum += item[key];
    });
    avg = Math.floor(sum / tree.length);
    return avg;
  }
}

function getChildren(tree, key, value) {
  if (tree) {
    if (value instanceof Array) {
      var _loop = function _loop(i) {
        var child = tree.find(function (item) {
          return item[key] == value[i];
        });

        if (child && child.children) {
          return {
            v: getChildren(child.children, key, value[i + 1])
          };
        }

        return {
          v: child == 'undefined' ? false : child
        };
      };

      for (var i = 0; i < value.length; i++) {
        var _ret = _loop(i);

        if ((0, _typeof2.default)(_ret) === "object") return _ret.v;
      }
    } else {
      var child = tree.find(function (item) {
        return item[key] == value;
      });

      if (child && child.children) {
        return getChildren(child.children, key, value);
      }

      return child == 'undefined' ? false : child;
    }
  }
}

var tree = {
  listToTree: listToTree,
  treeToList: treeToList,
  getChildren: getChildren,
  getParentsId: getParentsId,
  getChildrenId: getChildrenId,
  updateTree: updateTree,
  updateList: updateList,
  createTree: createTree,
  childrenToParent: childrenToParent
};
var _default = tree;
exports.default = _default;