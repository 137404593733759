"use strict";

var _interopRequireDefault = require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.authLogin = authLogin;
exports.mobileLogin = mobileLogin;
exports.register = register;
exports.logout = logout;
exports.getState = getState;

var _request = _interopRequireDefault(require("@/utils/request"));

/**
 * 账户登录
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
function login(data) {
  return (0, _request.default)({
    url: '/core/user/login',
    method: 'post',
    data: data
  });
}
/**
 * 授权登录
 * @param {}
 * @returns 
 */


function authLogin(data) {
  return (0, _request.default)({
    url: "/core/user/authLogin",
    method: "post",
    data: data
  });
}

;
/**
 * 手机登录
 * @param {}
 * @returns 
 */

function mobileLogin(data) {
  return (0, _request.default)({
    url: "/core/user/mobileLogin",
    method: "post",
    data: data
  });
}

;
/**
 * 用户注册
 * @param { }
 * @returns 
 */

function register(data) {
  return (0, _request.default)({
    url: "/core/user/register",
    method: "post",
    data: data
  });
}

;
/**
 * 用户登出
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */

function logout(data) {
  return (0, _request.default)({
    url: '/core/user/logout',
    method: 'post',
    data: data
  });
}
/**
 * 获取登录态
 * @return {[type]} [description]
 */


function getState() {
  return (0, _request.default)({
    url: '/core/user/state',
    method: 'get'
  });
}