"use strict";

var _interopRequireDefault = require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getImage = getImage;
exports.getCode = getCode;

var _request = _interopRequireDefault(require("@/utils/request"));

function getImage(data) {
  return (0, _request.default)({
    url: '/core/captcha/image',
    method: 'post',
    data: data
  });
}

function getCode(data) {
  return (0, _request.default)({
    url: '/core/captcha/mobile',
    method: 'post',
    data: data
  });
}