var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.app.name == "" || _vm.app.entry == "" || _vm.app.entry == "/"
        ? _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [
              _c(
                "keep-alive",
                { attrs: { include: _vm.cachedViews } },
                [_c("router-view", { key: _vm.path })],
                1
              )
            ],
            1
          )
        : _c("micro-app", {
            attrs: {
              name: _vm.app.name,
              url: _vm.app.entry,
              line: _vm.app.debug,
              "keep-alive": !_vm.app.debug,
              baseRoute: "/web"
            },
            on: {
              datachange: _vm.onDataChange,
              created: _vm.created,
              beforemount: _vm.beforemount,
              mounted: _vm.mounted,
              unmount: _vm.unmount,
              error: _vm.error
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }