var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "err-page" }, [
    _c(
      "div",
      { staticClass: "err-page-container" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { span: 12 } },
              [
                _c("h1", { staticClass: "text-jumbo text-ginormous" }, [
                  _vm._v("OOPS!")
                ]),
                _c("h2", [_vm._v("你没有权限去该页面")]),
                _c("h3", [_vm._v("如有不满请联系你领导")]),
                _c(
                  "el-button",
                  {
                    staticClass: "pan-back-btn",
                    attrs: { icon: "el-icon-arrow-left" },
                    on: { click: _vm.back }
                  },
                  [_vm._v("返回")]
                )
              ],
              1
            ),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("img", {
                attrs: {
                  src: _vm.errGif,
                  width: "313",
                  height: "428",
                  alt: "Girl has dropped her ice cream."
                }
              })
            ])
          ],
          1
        ),
        _vm._m(0)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright" }, [
      _vm._v("Powered by "),
      _c("a", { attrs: { href: "http://www.startcms.cn", target: "_blank" } }, [
        _vm._v("start-cms")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }