"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Logo',
  props: {
    collapse: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    logo: function logo() {
      return this.$store.getters.config ? this.$store.getters.config.logo : '';
    },
    title: function title() {
      return this.$store.getters.config ? this.$store.getters.config.title : '';
    }
  }
};
exports.default = _default;