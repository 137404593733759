"use strict";

var _interopRequireDefault = require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _auth = _interopRequireDefault(require("./auth"));

var install = function install(Vue) {
  Vue.directive('auth', _auth.default);
};

if (window.Vue) {
  window['auth'] = _auth.default;
  Vue.use(install); // eslint-disable-line
}

_auth.default.install = install;
var _default = _auth.default;
exports.default = _default;