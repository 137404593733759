"use strict";

var _interopRequireDefault = require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

var _objectSpread2 = _interopRequireDefault(require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _microApp = _interopRequireDefault(require("@micro-zoe/micro-app"));

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AppMain',
  watch: {
    $route: function $route() {
      this.setAppData();
    }
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['apps', 'user', 'token', 'authorize'])), {}, {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    path: function path() {
      return this.$route.path;
    },
    app: function app() {
      var name = this.$route.meta.app ? this.$route.meta.app : '';
      var app = this.apps.find(function (item) {
        return item.name == name;
      });

      if (typeof app == 'undefined') {
        return {
          name: '',
          entry: '/'
        };
      }

      if (app.entry.indexOf('http') == -1) {
        app.entry = window.location.origin + app.entry;
      }

      return app;
    }
  }),
  methods: {
    created: function created(e) {// console.log('元素被创建', e)
    },
    beforemount: function beforemount(e) {
      this.setAppData(); // console.log(e.detail.name, '即将被渲染',e)
    },
    mounted: function mounted(e) {// console.log(e.detail.name, '已经渲染完成',e)
    },
    unmount: function unmount(e) {// console.log(e.detail.name, '已经卸载',e)
    },
    error: function error(e) {
      console.log(e.detail.name, '渲染出错', e);
    },
    // 下发子应用数据
    setAppData: function setAppData() {
      _microApp.default.setData(this.app.name, {
        app: this.app,
        user: this.user,
        token: this.token,
        route: this.$route,
        authorize: this.authorize
      });
    },
    // 监听子应用数据
    onDataChange: function onDataChange(e) {
      var data = e.detail.data; // 控制基座路由

      if (data.route) {
        this.$router.push({
          path: data.route.path || '/',
          hash: data.route.hash || '',
          query: data.route.query || {},
          params: data.route.params || ''
        });
      } // 监听应用消息


      if (data.msg) {
        this.$store.dispatch('app/sendMsg', data.msg);
      }
    }
  }
};
exports.default = _default;