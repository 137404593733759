"use strict";

var _interopRequireDefault = require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.regexp.replace");

var _objectSpread2 = _interopRequireDefault(require("E:/start-cms/back-front/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _MenuItem = _interopRequireDefault(require("./MenuItem"));

var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));

//
//
//
//
//
//
//
//
var _default = {
  components: {
    MenuItem: _MenuItem.default
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["config", "appNavbar", "sidebar"])), {}, {
    textColor: function textColor() {
      return this.config.text_color;
    },
    backgroundColor: function backgroundColor() {
      var theme = this.config.background_color; // 修改全局scss在styles/variables.scss中定义，hover和active样式定义在sidebar.scss中

      var subMenuHover = this.mixColor(theme, 0.2) || "";
      document.getElementsByTagName("body")[0].style.setProperty("--myStyle", subMenuHover);
      return theme;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  methods: {
    getColorChannels: function getColorChannels(color) {
      color = color.replace("#", "");

      if (/^[0-9a-fA-F]{3}$/.test(color)) {
        color = color.split("");

        for (var i = 2; i >= 0; i--) {
          color.splice(i, 0, color[i]);
        }

        color = color.join("");
      }

      if (/^[0-9a-fA-F]{6}$/.test(color)) {
        return {
          red: parseInt(color.slice(0, 2), 16),
          green: parseInt(color.slice(2, 4), 16),
          blue: parseInt(color.slice(4, 6), 16)
        };
      } else {
        return {
          red: 255,
          green: 255,
          blue: 255
        };
      }
    },
    mixColor: function mixColor(color, percent) {
      var _this$getColorChannel = this.getColorChannels(color),
          red = _this$getColorChannel.red,
          green = _this$getColorChannel.green,
          blue = _this$getColorChannel.blue;

      if (percent > 0) {
        // shade given color
        red *= 1 - percent;
        green *= 1 - percent;
        blue *= 1 - percent;
      } else {
        // tint given color
        red += (255 - red) * percent;
        green += (255 - green) * percent;
        blue += (255 - blue) * percent;
      }

      return "rgb(".concat(Math.round(red), ", ").concat(Math.round(green), ", ").concat(Math.round(blue), ")");
    }
  }
};
exports.default = _default;